<template>
  <div>
    <Header />

    <div class="contains-blog">
      <div id="Navmenu">
        <!--   <div class="Header">
          <div class="HB1">
            <img src="../assets/Logo.svg" />
            <p>THE WEB, DEV & MKT AGENCY</p>
          </div>
          <div class="HB2">
            <a><p>Contact us</p></a>
            <img src="../assets/Menu.svg" />
          </div>
        </div>-->

        <div class="BarMenu">
          <a href="/magazine">
            <p>Magazine</p>
          </a>
          <div class="Menu">
            <ul>
              <li
                class="op-cat-1 seg"
                :class="{
                  active_li: liOpc == 'Home',
                  bor_cat6: liOpc == 'Home',
                }"
                @click="getOption('Home')"
              >
                Home
              </li>
              <li
                class="op-cat-2 seg"
                :class="{
                  active_li: liOpc == 'MARKETING',
                  bor_cat1: liOpc == 'MARKETING',
                }"
                @click="getOption('MARKETING')"
              >
                Marketing
              </li>
              <li
                class="op-cat-3 seg"
                :class="{
                  active_li: liOpc == 'DEVELOPMENT',
                  bor_cat2: liOpc == 'DEVELOPMENT',
                }"
                @click="getOption('DEVELOPMENT')"
              >
                Development
              </li>
              <li
                class="op-cat-4 seg"
                :class="{
                  active_li: liOpc == 'MEET THE BOLDS',
                  bor_cat5: liOpc == 'MEET THE BOLDS',
                }"
                @click="getOption('MEET THE BOLDS')"
              >
                Meet The Bolds
              </li>
              <li
                class="op-cat-5 seg"
                :class="{
                  active_li: liOpc == 'ENTREPREURNSHIP',
                  bor_cat6: liOpc == 'ENTREPREURNSHIP',
                }"
                @click="getOption('ENTREPREURNSHIP')"
              >
                Entrepreneurship
              </li>
              <li
                class="op-cat-6 seg"
                :class="{
                  active_li: liOpc == 'DESIGN',
                  bor_cat3: liOpc == 'DESIGN',
                }"
                @click="getOption('DESIGN')"
              >
                Design
              </li>
              <li
                class="op-cat-7 seg"
                :class="{
                  active_li: liOpc == 'PHOTOGRAPHY',
                  bor_cat4: liOpc == 'PHOTOGRAPHY',
                }"
                @click="getOption('PHOTOGRAPHY')"
              >
                Photography
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div v-if="this.liOpc != 'Home'" class="flexinMgz">
        <div class="S1 mgz">
          <div class="mkt-mgz">
            <div class="header-c-magazine">
              <p>{{ this.liOpc | split }}</p>
              <!---<div class="return" @click="getOption('Home')">
                  Regresar a la publicación
                </div>-->
            </div>
          </div>

          <div class="grid-mgz">
            <div v-if="sortedItems(visibleBlogs(allblogs2)).length == 0">
              <p class="notfound">No se encontraron items.</p>
            </div>
            <div v-else class="B3F2 woml ">
                <div
                  class="item-mgz pc"
                  v-for="(item, index) in sortedItems(visibleBlogs(allblogs2))"
                  :key="index"
                >
                  <a
                    v-if="item.statusblog == 'VISIBLE'"
                    :href="'/magazine/' + item.url"
                  >
                    <div
                      class="image-cont"
                      :style="{
                        'background-image':
                          'url(' +
                          urlpath.url() +
                          '/blog-img/' +
                          item.image +
                          ')',
                      }"
                    ></div>
                    <div class="info">
                      <p
                        class="category"
                        :class="{
                          col_cat1: item.category_name == 'MARKETING',
                          col_cat2: item.category_name == 'DEVELOPMENT',
                          col_cat3: item.category_name == 'DESIGN',
                          col_cat4: item.category_name == 'PHOTOGRAPHY',
                          col_cat5: item.category_name == 'MEET THE BOLDS',
                          col_cat6: item.category_name == 'ENTREPREURNSHIP',
                        }"
                      >
                        {{ item.category_name | split }}
                      </p>
                      <p
                        class="title"
                        :class="{
                          hov_cat1: item.category_name == 'MARKETING',
                          hov_cat2: item.category_name == 'DEVELOPMENT',
                          hov_cat3: item.category_name == 'DESIGN',
                          hov_cat4: item.category_name == 'PHOTOGRAPHY',
                          hov_cat5: item.category_name == 'MEET THE BOLDS',
                          hov_cat6: item.category_name == 'ENTREPREURNSHIP',
                        }"
                      >
                        {{ item.title }}
                      </p>
                    </div>
                  </a>
                </div>

              <div
                class="mo"
                v-for="(item, key) in sortedItems(visibleBlogs(allblogs2))"
                :key="key"
              >
                <a :href="'/magazine/' + item.url">
                  <div class="B3F2_B1">
                    <div class="B3Post">
                      <div
                        class="B3Img"
                        :style="{
                          'background-image':
                            'url(' +
                            urlpath.url() +
                            '/blog-img/' +
                            item.image +
                            ')',
                        }"
                      ></div>
                      <div class="B3Info">
                        <p
                          class="B3Etiq"
                          :class="{
                            col_cat1: item.category_name == 'MARKETING',
                            col_cat2: item.category_name == 'DESARROLLO',
                            col_cat3: item.category_name == 'DESIGN',
                            col_cat4: item.category_name == 'PHOTOGRAPHY',
                            col_cat5: item.category_name == 'MEET THE BOLDS',
                            col_cat6: item.category_name == 'ENTREPREURNSHIP',
                          }"
                        >
                          {{ item.category_name | splitTitle }}
                        </p>
                        <p
                          class="B3Text"
                          :class="{
                            hov_cat1: item.category_name == 'MARKETING',
                            hov_cat2: item.category_name == 'DESARROLLO',
                            hov_cat3: item.category_name == 'DESIGN',
                            hov_cat4: item.category_name == 'PHOTOGRAPHY',
                            hov_cat5: item.category_name == 'MEET THE BOLDS',
                            hov_cat6: item.category_name == 'ENTREPREURNSHIP',
                          }"
                        >
                          {{ limitWords(item.title) }}
                        </p>
                        <p class="B3Date">{{ item.dateblog }}</p>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="S2">
          <iframe
            class="If1"
            src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Flegrafica%2F&tabs=timeline&width=440&height=700&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
            width="100%"
            height="652vw"
            style="border: none; overflow: hidden"
            scrolling="no"
            frameborder="0"
            allowfullscreen="true"
            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
          ></iframe>

          <iframe
            class="If2"
            src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Flegrafica%2F&tabs=timeline&width=340&height=600&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
            width="340"
            height="600"
            style="border: none; overflow: hidden"
            scrolling="no"
            frameborder="0"
            allowfullscreen="true"
            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
          ></iframe>
          <img
            alt="img"
            class="image-sponsor"
            src="../assets/Rectangle64.svg"
          />
          <div class="S2Visto">
            <p class="S2LMV">Lo más visto</p>
            <div
              v-if="
                blogbytag != 'No se encontraron coincidencias.' &&
                blogbytag != 'error'
              "
            >
              <div
                class="item-news"
                v-for="(item, index) in blogbytag.slice(0, 3)"
                :key="index"
              >
                <a :href="'/magazine/' + item.url">
                  <div
                    class="imgLV"
                    :style="{
                      'background-image':
                        'url(' +
                        urlpath.url() +
                        '/blog-img/' +
                        item.image +
                        ')',
                    }"
                  >
                    <!--<img
                      :src="
                        urlpath.url() +
                        '/blog-img/' +
                        item.image
                      "
                    />-->
                  </div>
                  <div class="S2F2">
                    <p
                      class="S2Catg"
                      :class="{
                        col_cat1: item.category_name == 'MARKETING',
                        col_cat2: item.category_name == 'DEVELOPMENT',
                        col_cat3: item.category_name == 'DESIGN',
                        col_cat4: item.category_name == 'PHOTOGRAPHY',
                        col_cat5: item.category_name == 'MEET THE BOLDS',
                        col_cat6: item.category_name == 'ENTREPREURNSHIP',
                      }"
                    >
                      {{ item.category_name | split }}
                    </p>
                    <p class="S2Date">{{ dayFormat2(item.dateblog) }}</p>
                  </div>
                  <p
                    class="S2Text"
                    :class="{
                      hov_cat1: item.category_name == 'MARKETING',
                      hov_cat2: item.category_name == 'DEVELOPMENT',
                      hov_cat3: item.category_name == 'DESIGN',
                      hov_cat4: item.category_name == 'PHOTOGRAPHY',
                      hov_cat5: item.category_name == 'MEET THE BOLDS',
                      hov_cat6: item.category_name == 'ENTREPREURNSHIP',
                    }"
                  >
                    {{ item.title }}
                  </p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="home-cont" v-if="this.liOpc == 'Home'">
        <div class="B1">
          <div v-if="allblogs.length == 0" class="not-found">
            No se encontraron items.
          </div>
          <div v-else v-for="(item, key) in allblogs.slice(0, 5)" :key="key">
            <!--
            <a
              :href="'/magazine/' + item.url"
              v-if="key < 5 && item.statusblog == 'VISIBLE'"
            >
          -->
            <a
              :href="'/magazine/' + item.url"
              v-if="item.statusblog == 'VISIBLE'"
            >
              <div
                class="B1F1"
                :class="{ B1F1A: key > 1 }"
                :style="{
                  'background-image':
                    'url(' +
                    urlpath.url() +
                    '/blog-img/' +
                    item.image +
                    ')',
                }"
              >
                <div class="B1F1_B1" :class="{ B1F1_B10: key > 1 }">
                  <div class="B1F1_B1F1">
                    <p
                      class="B1F1_Etiq"
                      :class="{
                        B1F1_EtiqA: key > 1,
                        bg_cat1: item.category_name == 'MARKETING',
                        bg_cat2: item.category_name == 'DESARROLLO',
                        bg_cat3: item.category_name == 'DESIGN',
                        bg_cat4: item.category_name == 'PHOTOGRAPHY',
                        bg_cat5: item.category_name == 'MEET THE BOLDS',
                        bg_cat6: item.category_name == 'ENTREPREURNSHIP',
                      }"
                    >
                      {{ item.category_name | splitTitle }}
                    </p>
                    <p class="B1F1_Date">{{ item.dateblog }}</p>
                  </div>
                  <div class="B1F1_B1F2">
                    <p class="B1F1_Text" :class="{ B1F1_TextA: key > 1 }">
                      {{ item.title }}
                    </p>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>

        <div class="B1PH">
          <div v-if="allblogs.length == 0" class="not-found">
            No se encontraron items.
          </div>
          <div v-else v-for="(item, key) in allblogs" :key="key">
            <a :href="'/magazine/' + item.url">
              <div
                class="B1F1"
                :class="{
                  B1F1A: key < 1,
                  B1F1B: key > 0 && key < 3,
                  B1F1C: key > 2 && key < 5,
                }"
                :style="{
                  'background-image':
                    'url(' +
                    urlpath.url() +
                    '/blog-img/' +
                    item.image +
                    ')',
                }"
              >
                <div
                  :class="{
                    B1F1_B1A: key < 1,
                    B1F1_B1B: key > 0 && key < 3,
                    B1F1_B1C: key > 2 && key < 5,
                  }"
                >
                  <div class="B1F1_B1F1">
                    <p
                      class="B1F1_Etiq"
                      :class="{
                        B1F1_EtiqA: key < 1,
                        B1F1_EtiqB: key > 0 && key < 3,
                        B1F1_EtiqC: key > 2 && key < 5,
                        bg_cat1: item.category_name == 'MARKETING',
                        bg_cat2: item.category_name == 'DESARROLLO',
                        bg_cat3: item.category_name == 'DESIGN',
                        bg_cat4: item.category_name == 'PHOTOGRAPHY',
                        bg_cat5: item.category_name == 'MEET THE BOLDS',
                        bg_cat6: item.category_name == 'ENTREPREURNSHIP',
                      }"
                    >
                      {{ item.category_name | splitTitle }}
                    </p>
                    <p class="B1F1_Date">{{ item.dateblog }}</p>
                  </div>
                  <div class="B1F1_B1F2">
                    <p
                      :class="{
                        B1F1_TextA: key < 1,
                        B1F1_TextB: key > 0 && key < 3,
                        B1F1_TextC: key > 2 && key < 5,
                      }"
                    >
                      {{ item.title }}
                    </p>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>

        <div class="B2">
          <div class="B2F1">
            <p>Lo último</p>
          </div>

          <carousel
            :per-page="4"
            :mouse-drag="true"
            :navigation-enabled="true"
            :navigationNextLabel="nextB"
            :navigationPrevLabel="nextA"
            :loop="true"
            :paginationEnabled="false"
            class="slideA"
          >
            <slide v-for="(item, key) in FilterAsc" :key="key">
              <a :href="'/magazine/' + item.url">
                <div class="B2slide">
                  <div>
                    <div class="B2F2">
                      <div class="B2Post">
                        <div
                          class="B2Img"
                          :style="{
                            'background-image':
                              'url(' +
                              urlpath.url() +
                              '/blog-img/' +
                              item.image +
                              ')',
                          }"
                        ></div>
                        <div class="B2Info">
                          <p
                            class="B2Etiq"
                            :class="{
                              col_cat1: item.category_name == 'MARKETING',
                              col_cat2: item.category_name == 'DESARROLLO',
                              col_cat3: item.category_name == 'DESIGN',
                              col_cat4: item.category_name == 'PHOTOGRAPHY',
                              col_cat5: item.category_name == 'MEET THE BOLDS',
                              col_cat6: item.category_name == 'ENTREPREURNSHIP',
                            }"
                          >
                            {{ item.category_name | splitTitle }}
                          </p>
                          
                          <p
                            class="B2Text"
                            :class="{
                              hov_cat1: item.category_name == 'MARKETING',
                              hov_cat2: item.category_name == 'DESARROLLO',
                              hov_cat3: item.category_name == 'DESIGN',
                              hov_cat4: item.category_name == 'PHOTOGRAPHY',
                              hov_cat5: item.category_name == 'MEET THE BOLDS',
                              hov_cat6: item.category_name == 'ENTREPREURNSHIP',
                            }"
                          >
                            {{ item.title }}
                          </p>
                          <p class="B2Date">{{ item.dateblog }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </slide>
          </carousel>

          <carousel
            :per-page="2"
            :mouse-drag="true"
            :navigation-enabled="true"
            :navigationNextLabel="nextB"
            :navigationPrevLabel="nextA"
            :loop="true"
            :paginationEnabled="false"
            class="slideB"
          >
            <slide v-for="(item, key) in FilterAsc" :key="key">
              <a :href="'/magazine/' + item.url">
                <div class="B2slide">
                  <div>
                    <div class="B2F2">
                      <div class="B2Post">
                        <div
                          class="B2Img"
                          :style="{
                            'background-image':
                              'url(' +
                              urlpath.url() +
                              '/blog-img/' +
                              item.image +
                              ')',
                          }"
                        ></div>
                        <div class="B2Info">
                          <p
                            class="B2Etiq"
                            :class="{
                              col_cat1: item.category_name == 'MARKETING',
                              col_cat2: item.category_name == 'DESARROLLO',
                              col_cat3: item.category_name == 'DESIGN',
                              col_cat4: item.category_name == 'PHOTOGRAPHY',
                              col_cat5: item.category_name == 'MEET THE BOLDS',
                              col_cat6: item.category_name == 'ENTREPREURNSHIP',
                            }"
                          >
                            {{ item.category_name | splitTitle }}
                          </p>
                          <p class="B2Text">{{ limitWords(item.title) }}</p>
                          <p class="B2Date">{{ item.dateblog }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </slide>
          </carousel>

          <!--<agile :options="options" class="slideA">
          <div v-for="(item, key) in FilterAsc" :key="key">

            <a :href="'/magazine/'+ item.url ">            
            <div class="B2slide">
              <div>
                <div class="B2F2">
                  <div class="B2Post">
                    <div 
                    class="B2Img"
                    :style="{ 'background-image': 'url(' + urlpath.url()+'/blog-img/'+item.image + ')' } "></div>
                    <div class="B2Info">
                      <p class="B2Etiq">{{ item.category_name }}</p>
                      <p class="B2Text">{{ item.title }}</p>
                      <p class="B2Date">{{ item.dateblog }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </a>

          </div>
          <template slot="prevButton">
            <div class="divPrev0">
              <img class="PButton" src="../assets/Frame_Prev.svg">
            </div>
          </template>
          <template slot="nextButton">
            <div class="divNext0">
              <img class="NButton" src="../assets/Frame_Next.svg">
            </div>
          </template>
        </agile>
        
        <agile :options="opcions" class="slideB">
          <div v-for="(item, key) in FilterAsc" :key="key">

            <a :href="'/magazine/'+ item.url ">
            <div class="B2slide">
              <div>
                <div class="B2F2">
                  <div class="B2Post">
                    <div 
                    class="B2Img"
                    :style="{ 'background-image': 'url(' + urlpath.url()+'/blog-img/'+item.image + ')' } "></div>
                    <div class="B2Info">
                      <p class="B2Etiq">{{ item.category_name }}</p>
                      <p class="B2Text">{{ item.title }}</p>
                      <p class="B2Date">{{ item.dateblog }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </a>

          </div>
          <template slot="prevButton">
            <div class="divPrev">
              <img class="PButton" src="../assets/Frame_Prev.svg">
            </div>
          </template>
          <template slot="nextButton">
            <div class="divNext">
              <img class="NButton" src="../assets/Frame_Next.svg">
            </div>
          </template>

        </agile>-->
        </div>

        <div class="B3">
          <div class="B3F1">
            <p>Lo más visto</p>
          </div>
          <div class="B3B4F2F">
            <div class="B3F2">
              <div v-for="(item, key) in blogbytag.slice(0, 4)" :key="key">
                <a :href="'/magazine/' + item.url">
                  <div class="B3F2_B1">
                    <div class="B3Post">
                      <div
                        class="B3Img"
                        :style="{
                          'background-image':
                            'url(' +
                            urlpath.url() +
                            '/blog-img/' +
                            item.image +
                            ')',
                        }"
                      ></div>
                      <div class="B3Info">
                        <p
                          class="B3Etiq"
                          :class="{
                            col_cat1: item.category_name == 'MARKETING',
                            col_cat2: item.category_name == 'DESARROLLO',
                            col_cat3: item.category_name == 'DESIGN',
                            col_cat4: item.category_name == 'PHOTOGRAPHY',
                            col_cat5: item.category_name == 'MEET THE BOLDS',
                            col_cat6: item.category_name == 'ENTREPREURNSHIP',
                          }"
                        >
                          {{ item.category_name | splitTitle }}
                        </p>
                        <p
                          class="B3Text"
                          :class="{
                            hov_cat1: item.category_name == 'MARKETING',
                            hov_cat2: item.category_name == 'DESARROLLO',
                            hov_cat3: item.category_name == 'DESIGN',
                            hov_cat4: item.category_name == 'PHOTOGRAPHY',
                            hov_cat5: item.category_name == 'MEET THE BOLDS',
                            hov_cat6: item.category_name == 'ENTREPREURNSHIP',
                          }"
                        >
                          {{ limitWords(item.title) }}
                        </p>
                        <p class="B3Date">{{ item.dateblog }}</p>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div class="B3F2_B2">
              <iframe
                src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Flegrafica%2F&tabs=timeline&width=445&height=670&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
                width="442"
                height="500"
                style="border: none; overflow: hidden"
                scrolling="no"
                frameborder="0"
                allowfullscreen="true"
                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
              ></iframe>
              <img src="../assets/Rectangle64.svg" />
            </div>
          </div>
        </div>

        <div
          class="B4_0"
          v-if="allItemsCondition != 'No se encontraron coincidencias'"
        >
          <div class="B4_0F1">
            <p class="B4_0P1">Últimos videos</p>
            <div class="B4_0F0">
              <p>Ver todos</p>
              <div class="B4_0img">
                <img src="../assets/Vector.png" />
              </div>
            </div>
          </div>

          <div class="B4_0F2 centered-v-c" v-if="allItems.length < 4">
            <div v-for="(item, key) in allItems" :key="key">
              <div
                @click="VideoPlay(item.url)"
                class="B4_0F2B1"
                :style="{
                  'background-image':
                    'url(' +
                    urlpath.url() +
                    '/videos-img/' +
                    item.image +
                    ')',
                }"
              >
                <div class="B4_0F3">
                  <p
                    class="B4_0P2"
                    :class="{
                      bg_cat1: item.category_name == 'MARKETING',
                      bg_cat2: item.category_name == 'DESARROLLO',
                      bg_cat3: item.category_name == 'DESIGN',
                      bg_cat4: item.category_name == 'PHOTOGRAPHY',
                      bg_cat5: item.category_name == 'MEET THE BOLDS',
                      bg_cat6: item.category_name == 'ENTREPREURNSHIP',
                    }"
                  >
                    {{ item.category_name | splitTitle }}
                  </p>
                  <p class="B4_0P3">{{ item.datevideo }}</p>
                </div>
                <p class="B4_0P4">{{ item.title }}</p>
              </div>

              <button class="plyButton" @click="VideoPlay(item.url)">
                <img src="../assets/Vector_Play.png" />
              </button>
            </div>
          </div>

          <div class="B4_0F2" v-else-if="allItems.length > 3">
            <agile :options="opcionesD" class="VideosD">
              <div v-for="(item, key) in allItems" :key="key">
                <div
                  @click="VideoPlay(item.url)"
                  class="B4_0F2B1"
                  :style="{
                    'background-image':
                      'url(' +
                      urlpath.url() +
                      '/videos-img/' +
                      item.image +
                      ')',
                  }"
                >
                  <div class="video-linearg">
                    <div class="B4_0F3">
                      <p
                        class="B4_0P2"
                        :class="{
                          bg_cat1: item.category_name == 'MARKETING',
                          bg_cat2: item.category_name == 'DESARROLLO',
                          bg_cat3: item.category_name == 'DESIGN',
                          bg_cat4: item.category_name == 'PHOTOGRAPHY',
                          bg_cat5: item.category_name == 'MEET THE BOLDS',
                          bg_cat6: item.category_name == 'ENTREPREURNSHIP',
                        }"
                      >
                        {{ item.category_name | splitTitle }}
                      </p>
                      <p class="B4_0P3">{{ item.datevideo }}</p>
                    </div>
                    <p class="B4_0P4">{{ item.title }}</p>
                  </div>
                </div>

                <button class="plyButton" @click="VideoPlay(item.url)">
                  <img src="../assets/Vector_Play.png" />
                </button>
              </div>

              <template slot="prevButton">
                <div class="divPrev3">
                  <img class="PButton3" src="../assets/Frame_AL.png" />
                </div>
              </template>
              <template slot="nextButton">
                <div class="divNext3">
                  <img class="NButton3" src="../assets/Frame_AR.png" />
                </div>
              </template>
            </agile>
          </div>
          <div
            class="modals"
            :class="{ modalShow: !showmodal }"
            @click="closeM()"
          >
            <div class="modal-content" @click.stop="">
              <div @click="closeM()" class="MHeader">
                <div class="menu-btn-cont">
                  <input
                    type="checkbox"
                    name="check"
                    id="checkmenus"
                    v-model="istrue12"
                    :true-value="true"
                    :false-value="true"
                  />
                  <label for="checkmenus">
                    <div class="menu-btn video-close">
                      <div
                        class="bars-cont"
                        :class="{ animation1: istrue12, animation2: istrue22 }"
                      >
                        <div class="bar uno video-cross" />
                        <div class="bar due video-cross" />
                      </div>
                    </div>
                  </label>
                </div>
              </div>
              <div id="diframes" class="MContent"></div>
            </div>
          </div>
        </div>

        <div
          class="B4P"
          v-if="allItemsCondition != 'No se encontraron coincidencias'"
        >
          <div class="B4P_F1">
            <p class="B4P_P1">Últimos videos</p>
            <div class="B4P_Img1">
              <img src="../assets/plus.png" />
            </div>
          </div>

          <div class="B4P_Video">
            
            
          <carousel
            :per-page="1"
            :mouse-drag="true"
            :navigation-enabled="true"
            :navigationNextLabel="nextB"
            :navigationPrevLabel="nextA"
            :loop="true"
            :paginationEnabled="false"
            class="Agl_Vid"
          >
            <slide v-for="(item, key) in allItems" :key="key">
                <div
                  class="B4_0F2B1"
                  @click="VideoPlay2(item.url)"
                  :style="{
                    'background-image':
                      'url(' +
                      urlpath.url() +
                      '/videos-img/' +
                      item.image +
                      ')',
                  }"
                >
                  <div class="B4P_mid">
                    <div class="B4P_imgP">
                      <button class="plyButton" @click="VideoPlay2(item.url)">
                        <img src="../assets/Vector_Play.png" />
                      </button>
                    </div>
                  </div>
                  <div class="video-linearg">
                    <div class="B4_0F3">
                      <p
                        class="B4_0P2"
                        :class="{
                          bg_cat1: item.category_name == 'MARKETING',
                          bg_cat2: item.category_name == 'DESARROLLO',
                          bg_cat3: item.category_name == 'DESIGN',
                          bg_cat4: item.category_name == 'PHOTOGRAPHY',
                          bg_cat5: item.category_name == 'MEET THE BOLDS',
                          bg_cat6: item.category_name == 'ENTREPREURNSHIP',
                        }"
                      >
                        {{ item.category_name | splitTitle }}
                      </p>
                      <p class="B4_0P3">{{ item.datevideo }}</p>
                    </div>
                    <p class="B4_0P4">{{ item.title }}</p>
                  </div>
                </div>
            </slide>
          </carousel>
           <!-- <agile :options="opciones" class="Agl_Vid">
              <div v-for="(item, key) in allItems" :key="key">
                <div
                  class="B4_0F2B1"
                  @click="VideoPlay2(item.url)"
                  :style="{
                    'background-image':
                      'url(' +
                      urlpath.url() +
                      '/videos-img/' +
                      item.image +
                      ')',
                  }"
                >
                  <div class="B4P_mid">
                    <div class="B4P_imgP">
                      <button class="plyButton" @click="VideoPlay2(item.url)">
                        <img src="../assets/Vector_Play.png" />
                      </button>
                    </div>
                  </div>
                  <div class="video-linearg">
                    <div class="B4_0F3">
                      <p
                        class="B4_0P2"
                        :class="{
                          bg_cat1: item.category_name == 'MARKETING',
                          bg_cat2: item.category_name == 'DESARROLLO',
                          bg_cat3: item.category_name == 'DESIGN',
                          bg_cat4: item.category_name == 'PHOTOGRAPHY',
                          bg_cat5: item.category_name == 'MEET THE BOLDS',
                          bg_cat6: item.category_name == 'ENTREPREURNSHIP',
                        }"
                      >
                        {{ item.category_name | splitTitle }}
                      </p>
                      <p class="B4_0P3">{{ item.datevideo }}</p>
                    </div>
                    <p class="B4_0P4">{{ item.title }}</p>
                  </div>
                </div>
              </div>

              <template slot="prevButton">
                <div class="divPrev1">
                  <img class="PButton" src="../assets/Frame_AL.png" />
                </div>
              </template>
              <template slot="nextButton">
                <div class="divNext1">
                  <img class="NButton" src="../assets/Frame_AR.png" />
                </div>
              </template>
            </agile>-->
          </div>

          <div
            class="modals"
            :class="{ modalShow: !showmodal }"
            @click="closeM()"
          >
            <div class="modal-content" @click.stop="">
              <div @click="closeM()" class="MHeader">
                <div class="menu-btn-cont">
                  <input
                    type="checkbox"
                    name="check"
                    id="checkmenus"
                    v-model="istrue12"
                    :true-value="true"
                    :false-value="true"
                  />
                  <label for="checkmenus">
                    <div class="menu-btn video-close">
                      <div
                        class="bars-cont"
                        :class="{ animation1: istrue12, animation2: istrue22 }"
                      >
                        <div class="bar uno video-cross" />
                        <div class="bar due video-cross" />
                      </div>
                    </div>
                  </label>
                </div>
              </div>
              <div id="diframess" class="MContent"></div>
            </div>
          </div>
        </div>

        <div class="B4">
          <div class="B4F1">
            <p>Últimos colaboradores</p>
          </div>

          <div class="B4F2">
            <div v-for="(item, key) in dataColab" :key="key">
              <div
                class="B4F2_B1"
                v-if="item != 'No se encontraron coincidencias.'"
              >
                <div
                  class="B4Image"
                  :style="{
                    'background-image':
                      'url(' +
                      urlpath.url() +
                      `/colaboradores-img/` +
                      item.image +
                      ')',
                  }"
                ></div>
                <p class="B4Name">{{ item.name }}</p>
                <p class="B4Puesto">{{ item.descrp }}</p>
                <div class="B4Fund" v-html="item.about"></div>
              </div>
            </div>
          </div>

          <div class="B5">
            <div class="B5B1">
              <p class="B5P1">Suscríbete</p>
              <p class="B5P2">
                Y recibe nuestro contenido antes que nadie. Queremos que siempre
                tengas temas de qué hablar.
              </p>
            </div>

            <img class="B5Img1" src="../assets/Frame_0.png" />

            <form class="sendForm">
              <input class="sendInp" placeholder="fernando@legrafica.mx" />
              <button class="sendSubmit" type="submit">
                <img src="../assets/Vector_Send.png" />
              </button>
            </form>
          </div>

          <div class="CustomDiv">
          <carousel
            :per-page="2"
            :mouse-drag="true"
            :navigation-enabled="true"
            :navigationNextLabel="nextB"
            :navigationPrevLabel="nextA"
            :loop="true"
            :paginationEnabled="false"
            class="customSlide"
          >
            <slide 
                v-for="(item, key) in dataColab"
                :key="key">
                <div class="B4F2_B1">
                  <div
                    class="B4Image"
                    :style="{
                      'background-image':
                        'url(' +
                        urlpath.url() +
                        `/colaboradores-img/` +
                        item.image +
                        ')',
                    }"
                  ></div>
                  <p class="B4Name">{{ item.name }}</p>
                  <p class="B4Puesto">{{ item.descrp }}</p>
                  <p class="B4Fund">{{ item.title }}</p>
                </div>
            </slide>
          </carousel>

          </div>


          <div class="B4B2">
            <iframe
              src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Flegrafica%2F&tabs=timeline&width=445&height=670&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
              width="340"
              height="500"
              style="border: none; overflow: hidden"
              scrolling="no"
              frameborder="0"
              allowfullscreen="true"
              allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
            ></iframe>
            <img src="../assets/Rectangle64.svg" />
          </div>
        </div>

        <div class="B5P">
          <div class="B5B1">
            <p class="B5P1">Suscríbete</p>
            <p class="B5P2">
              Y recibe nuestro contenido antes que nadie. Queremos que siempre
              tengas temas de qué hablar.
            </p>
          </div>

          <img class="B5Img1" src="../assets/Frame_0.png" />

          <form class="sendForm">
            <input class="sendInp" placeholder="fernando@legrafica.mx" />
            <button class="sendSubmit" type="submit">
              <img src="../assets/Vector_Send.png" />
            </button>
          </form>

          <img class="B5Img2" src="../assets/Frame_0.png" />
        </div>
      </div>

      <Footer />
    </div>
  </div>
</template>

<script>
import Header from "../components/on-site/Header.vue";
import Footer from "../components/on-site/Footer.vue";
import { mapActions } from "vuex";
import { VueAgile } from "vue-agile";
let urlpath = require("./../global/url");
import dayjs from "dayjs";
import { es } from "dayjs/locale/es";

export default {
  name: "Blog",
  data() {
    return {
      nextA: '<div class="divPrev0"><p class="NButton"><</p></div>',
      nextB: '<div class="divNext0"><p class="NButton">></p></div>',
      urlpath: urlpath,
      liOpc: "Home",
      blogOpc: "Desarrollo",

      istrue12: true,
      istrue22: false,

      newarr: [],
      pos: 0,
      colabarr: [],
      showmodal: false,

      options: {
        infinite: true,
        slidesToShow: 4,
        navButtons: true,
        dots: false,
      },
      opcions: {
        infinite: true,
        slidesToShow: 2,
        navButtons: true,
        dots: false,
      },
      opcionesD: {
        infinite: true,
        slidesToShow: 3,
        navButtons: true,
        dots: false,
      },
      opciones: {
        infinite: true,
        slidesToShow: 1,
        navButtons: true,
        dots: false,

        mobileFirst: true,
      },
    };
  },
  components: {
    Header,
    Footer,
    agile: VueAgile,
  },
  computed: {
    dataColab() {
      return this.$store.getters["colaboradores/data"];
    },
    dataV() {
      return this.$store.getters["videos/data"];
    },
    allItems() {
      return Object.keys(this.dataV).map((pid) => this.dataV[pid]);
    },
    allItemsCondition() {
      let condicion = this.$store.getters["videos/data"];
      let tipo = typeof condicion;
      if (tipo == Object) {
        condicion = condicion.replace(/["']/g, "");
      } else {
        return condicion;
      }

      return condicion;
    },

    categorias() {
      return this.$store.getters["blog_category/data"];
    },
    data() {
      return this.$store.getters["blog/data"];
    },

    blogbytag() {
      let array = this.$store.getters["blog4/data"];

      return array;
    },

    FilterAsc() {
      let array = this.$store.getters["blog/data"];
      let encontrados = [];
      for (const item of array) {
        if (item.statusblog == "VISIBLE") {
          encontrados.push(item);
        }
      }
      this.destacados = encontrados;
      return this.destacados;
    },

    allblogs() {
      let array = this.$store.getters["blog2/data"];

      let encontrados = [];
      for (const item of array) {
        if (item.orden != "NA") {
          encontrados.push(item);
        }
      }
      this.destacados = encontrados;
      this.destacados.sort(function (a, b) {
        return a.orden - b.orden;
      });
      /*
      if(this.liOpc == 'Home'){
        this.destacados;
      }else{
        this.destacados = this.destacados.filter(function(n){this.liOpc = n.category_name;});     
      }
      */
      return this.destacados;
    },

    allblogs2() {
      let array = this.$store.getters["blog2/data"];
      let encontrados = [];
      for (const item of array) {
        if (item.orden != "NA") {
          encontrados.push(item);
        }
      }
      this.destacados = encontrados;
      return array;
    },
    filterarr() {
      let ArrPr = this.colabarr;

      for (let i = 0; i < 2; i++) {
        this.newarr.pop();
      }

      for (let i = 0; i < ArrPr.length; i++) {
        if (ArrPr[this.pos].id == ArrPr[i].id) {
          if (this.pos == ArrPr.length - 1) {
            this.newarr.push(ArrPr[this.pos]);
            this.newarr.push(ArrPr[0]);
          } else {
            this.newarr.push(ArrPr[this.pos]);
            this.newarr.push(ArrPr[this.pos + 1]);
          }
        }
      }
      return this.newarr;
    },

    colabFilter() {
      const colab = this.data;

      for (var i = 0; i < colab.length; i++) {
        if (colab[i].colaborador.name != "No se encontraron coincidencias.") {
          this.colabarr.push(colab[i]);
        }
      }

      return this.colabarr;
    },
  },
  async created() {
    this.getAllInfoClb("colaboradores");
    this.getAllInfoVdo("videos");
    this.getAllInfoBct("blog_category");
    this.getAllInfoBlo("blog");
    await this.getAllInfoB2("blog");
    if (screen.width < 769) {
      this.SO = 2;
    }

    //obtener blog por etiqueta mas visto.
    await this.getAllInfoTg("tag");
    let etiquetas = this.$store.getters["tag/data"];
    let id_lomasvisto = null;
    for (const item of etiquetas) {
      if (item.name == "LO MAS VISTO") {
        id_lomasvisto = item.id;
      }
    }

    if (id_lomasvisto != null) {
      let payload4 = {
        id: id_lomasvisto,
        option: "blog-by-tag",
      };

      await this.getInfoByIdB4(payload4);
    }

    //Aqui se filtran los colaboradores
    const colab = this.data;

    for (var i = 0; i < colab.length; i++) {
      if (colab[i].colaborador != "No se encontraron coincidencias.") {
        this.colabarr.push(colab[i]);
      }
    }

    //Aqui se termina el filtro de colaboradores
  },

  updated() {
    if (this.search_value != this.updsearchcli) {
      this.updsearchcli = this.search_value;

      let option = "videos";

      let value = this.search_value;

      let payload = {
        option: option,
        search: value,
      };
      if (value != "") {
        this.$store.dispatch("videos/searchVdo", payload);
      } else {
        this.getAllInfoVdo("videos");
      }
    }
  },

  methods: {
    ...mapActions("blog_category", ["getAllInfoBct"]),
    ...mapActions("blog", ["getAllInfoBlo"]),
    ...mapActions("blog2", ["getAllInfoB2"]),
    ...mapActions("blog4", ["getInfoByIdB4"]),
    ...mapActions("tag", ["getAllInfoTg"]),
    ...mapActions("videos", ["getAllInfoVdo"]),
    ...mapActions("videos", ["setAddedVdo"]),
    ...mapActions("colaboradores", ["getAllInfoClb"]),
    ucase(titulo) {
      return titulo.toUpperCase();
    },
    limitWords(yourString){
      let maxLength = 60
      let trimmedString = yourString.substr(0, maxLength);
      trimmedString = trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(" ")))
      return trimmedString + '...'
    },
    dayFormat(fecha) {
      return dayjs(fecha).format("DD/MM/YYYY");
    },
    dayFormat2(fecha) {
      dayjs.locale("es");
      return dayjs(fecha).format("DD/MMMM/YYYY");
    },
    visibleBlogs(array) {
      array = array.filter(function (n) {
        return n.statusblog == "VISIBLE";
      });
      return array;
    },
    sortedItems(array) {
      let cat = this.liOpc;
      if (this.liOpc == "Home") {
        array;
      } else if (this.liOpc != "Home") {
        array = array.filter(function (n) {
          return n.category_name == cat;
        });
      }
      return array;
    },
    srcImg2: function (img) {
      //para el colaborador
      let src = `${urlpath.url()}/EWw_VE3/0l6-jgR/colaboradores-img/${img}`;
      return src;
    },
    getOption: function (option) {
      this.liOpc = option;
    },

    VideoPlay(enlace) {
      //document.open(this.dataVid.url)
      var changeV = this.showmodal;
      this.showmodal = !changeV;
      //document.getElementById('diframe').innerHTML= this.dataVid.url
      document.getElementById("diframes").innerHTML = enlace;
    },
    VideoPlay2(enlace) {
      //document.open(this.dataVid.url)
      var changeV = this.showmodal;
      this.showmodal = !changeV;
      //document.getElementById('diframe').innerHTML= this.dataVid.url
      document.getElementById("diframess").innerHTML = enlace;
    },
    closeM() {
      var changeV = this.istrue12;
      this.showmodal = !changeV;
    },

    search: async function (value) {
      let option = "videos";

      let payload = {
        option: option,
        search: value,
      };
      if (value != "") {
        this.$store.dispatch("videos/searchVdo", payload);
      } else {
        this.getAllInfoVdo("videos");
      }
    },

    next: function () {
      let algo = this.pos;
      this.pos = algo + 1;
      if (this.pos == this.colabarr.length) {
        this.pos = 0;
      }
    },
    prev: function () {
      let algo = this.pos;
      this.pos = algo - 1;

      if (this.pos == -1) {
        this.pos = this.colabarr.length - 1;
      }
    },
  },
  filters: {
    split: (value) => {
      if (!value) return "";
      value = value.toString();

      let splitStr = value.toLowerCase().split(" ");
      let len = splitStr.length;
      let i;

      if (len == 1) {
        return splitStr[0].charAt(0).toUpperCase() + splitStr[0].substring(1);
      }

      for (i = 0; i < len; i++) {
        splitStr[i] =
          splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      let nameCap = splitStr.join(" ");
      let newName = nameCap.split(" ").slice(0).join(" "); //cantidad de palabras quitadas.

      return newName;
    },
    splitTitle: (value) => {
      if (!value) return "";
      value = value.toString();
      let splitStr = value.toLowerCase().split(" ");
      let len = splitStr.length;
      let i;

      for (i = 0; i < len; i++) {
        splitStr[i] =
          splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      let nameCap = splitStr.join(" ");

      return nameCap;
    },
  },
  watch: {
        $route: {
            immediate: true,
            handler(to, from) {
                document.title = to.meta.title || 'Magazine - Legrafica';
            }
        },
  }
};
</script>

<style scoped>
.pc {
  display: block;
}
.mo {
  display: none;
}
p {
  margin-top: 0;
  margin-bottom: 0;
}
p.ql-align-justify {
  margin: 0;
}
.header-c-magazine {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4.167vw;
}
.return {
  color: #aeaeae;
  font-family: "Gramatika-Bold";
  font-style: normal;
  font-weight: 400;
  font-size: 0.938vw;
  line-height: 121.3%;
  cursor: pointer;
}
.header-c-magazine p {
  font-family: "Gramatika-Medium";
  font-style: normal;
  font-weight: 400;
  font-size: 3.125vw;
  line-height: 121.3%;
  color: #987ef3;
  margin: 0;
}
.grid-mgz {
  display: flex;
  flex-wrap: wrap;
}
.image-cont {
  width: 100%;
  height: 15.885vw;
  background-size: 170%;
  background-repeat: no-repeat;
  background-position: center;
  transition: 0.5s;
  background-color: #ffb6ea;
}
.item-mgz:hover .image-cont {
  background-size: 180%;
}
.S1.mgz {
  width: 56.208vw;
  /* margin: auto;*/
  padding: 2vw 0;
}
.item-mgz {
  width: 18.229vw;
  margin: 0 0.313vw 0 0;
}
.item-mgz p.category {
  font-family: "Gramatika-Black";
  font-style: normal;
  font-weight: 400;
  font-size: 0.781vw;
  line-height: 103.8%;
  color: #ef67c2;
  margin: 0 0 1.302vw;
}
.item-mgz p.title {
  font-family: "Gramatika-Medium";
  font-style: normal;
  font-weight: 400;
  font-size: 1.302vw;
  line-height: 125%;
  color: #231f20;
  margin: 0;
  transition: 0.5s;
}
.item-mgz .info {
  margin: 2.292vw 0;
}
p.notfound {
  font-family: "Gramatika-Medium";
  font-style: normal;
  font-weight: 400;
  font-size: 1.875vw;
  line-height: 125%;
  color: #bababa;
  margin: 0;
}

.seg {
  transition: 0.5s;
  border-bottom: 0.3vw solid transparent;
}
.op-cat-1:hover {
  color: var(--entrepreurnship) !important;
  border-color: var(--entrepreurnship) !important;
}
.op-cat-2:hover {
  color: var(--marketing) !important;
  border-color: var(--marketing) !important;
}
.op-cat-3:hover {
  color: var(--development) !important;
  border-color: var(--development) !important;
}
.op-cat-4:hover {
  color: var(--meet) !important;
  border-color: var(--meet) !important;
}
.op-cat-5:hover {
  color: var(--entrepreurnship) !important;
  border-color: var(--entrepreurnship) !important;
}
.op-cat-6:hover {
  color: var(--desing) !important;
  border-color: var(--desing) !important;
}
.op-cat-7:hover {
  color: var(--photography) !important;
  border-color: var(--photography) !important;
}

.B2Post:hover .hov_cat1,
.B3Post:hover .hov_cat1,
.item-mgz:hover .hov_cat1 {
  color: var(--marketing) !important;
}
.B2Post:hover .hov_cat2,
.B3Post:hover .hov_cat2,
.item-mgz:hover .hov_cat2 {
  color: var(--development) !important;
}
.B2Post:hover .hov_cat3,
.B3Post:hover .hov_cat3,
.item-mgz:hover .hov_cat3 {
  color: var(--desing) !important;
}
.B2Post:hover .hov_cat4,
.B3Post:hover .hov_cat4,
.item-mgz:hover .hov_cat4 {
  color: var(--photography) !important;
}
.B2Post:hover .hov_cat5,
.B3Post:hover .hov_cat5,
.item-mgz:hover .hov_cat5 {
  color: var(--meet) !important;
}
.B2Post:hover .hov_cat6,
.B3Post:hover .hov_cat6,
.item-mgz:hover .hov_cat6 {
  color: var(--entrepreurnship) !important;
}
.centered-v-c {
  justify-content: center;
}
.not-found {
  text-align: center;
  font-size: 1vw;
  line-height: 1vw;
  font-family: "Gramatika-Medium";
}
.menu-btn {
  width: 7.32421875vh;
  height: 7.32421875vh;
  border: 1px solid #987ef3;
  border-radius: 7.32421875vh;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 99;
  transition: 0.5s;
}
.menu-btn.video-close {
  background-color: white;
  z-index: 500;
}
.menu-btn:hover {
  transform: scale(1.1);
  border: 1px solid #ef67c2;
}
.bar {
  transition: 0.5s;
  background-color: #987ef3;
}
.menu-btn:hover .bar {
  background-color: #ef67c2;
}
.bars-cont {
  display: flex;
  flex-direction: column;
  height: 1.35vh;
  width: fit-content;
  justify-content: space-between;
  transition: 0.5s;
}
.video-cross {
  background-color: #ef67c2 !important;
}
.bar.uno {
  width: 3.125vh;
  height: 0.390625vh;
  transition: 0.5s;
  animation-name: barunoM;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
}
.bar.due {
  width: 3.80859375vh;
  height: 0.390625vh;
  animation-name: barunoM1;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
}
.animation1 .bar.uno {
  width: 3.80859375vh;
  position: relative;
  animation-name: baruno;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  transform: rotate(0);
}
.animation1 .bar.due {
  animation-name: baruno1;
  position: relative;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  position: relative;
  transform: rotate(0);
}
#checkmenus {
  display: none;
}
#checkmenus:checked .bars-cont {
  height: 0.390625vh;
}
#checkmenus:checked ~ .menu-desp {
  height: 100%;
  pointer-events: all;
}
.bars-cont.animation1 {
  animation-name: heightchange;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
}
.video-linearg {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background: linear-gradient(0deg, black, transparent);
}
@keyframes heightchange {
  0% {
    height: 1.35vh;
  }
  25% {
    height: 1.35vh;
  }
  50% {
    height: 1.35vh;
  }
  100% {
    height: 2vh;
    animation-duration: 1.5s;
  }
}
@keyframes baruno {
  0% {
    margin-top: 0;
    width: 3.125vh;
  }
  25% {
    margin-top: 0;
    width: 3.80859375vh;
    top: 0;
  }
  50% {
    margin-top: 0.5vh;
    top: 0.5vh;
    transform: rotate(0);
  }
  100% {
    margin-top: 0.5vh;
    top: 0.5vh;
    transform: rotate(50deg);
    height: 0.390625vh;
    animation-duration: 1.5s;
  }
}
@keyframes baruno1 {
  0% {
    margin-top: 0;
  }
  25% {
    margin-top: 0;
  }
  50% {
    margin-top: 0;
    top: 0;
    transform: rotate(0);
  }
  100% {
    margin-top: 0;
    top: -0.6vh;
    transform: rotate(135deg);
    height: 0.390625vh;
    animation-duration: 1.5s;
  }
}
@keyframes barunoM {
  0% {
    margin-top: 0.5vh;
    top: 0.5vh;
    transform: rotate(50deg);
    height: 0.390625vh;
    animation-duration: 1.5s;
  }
  25% {
    margin-top: 0.5vh;
    top: 0.5vh;
    transform: rotate(0);
  }
  50% {
    margin-top: 0;
    width: 3.80859375vh;
    top: 0;
  }
  100% {
    margin-top: 0;
    width: 3.125vh;
  }
}
@keyframes barunoM1 {
  0% {
    margin-top: 0;
    top: -0.6vh;
    transform: rotate(135deg);
    height: 0.390625vh;
    animation-duration: 1.5s;
  }
  25% {
    margin-top: 0;
    top: 0;
    transform: rotate(0);
  }
  50% {
    margin-top: 0;
  }
  100% {
    margin-top: 0;
  }
}

.CustomDiv {
  display: none;
}
.slideB {
  display: none !important;
}
.B4B2 {
  display: none;
}

.PButton {
  width: 0.5vw;
  margin-top: 2vw;
}
.NButton {
  width: 0.5vw;
  margin-top: 2vw;
}

.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: 0vw;
  width: calc(var(--swiper-navigation-size) / 44 * 27);
  height: var(--swiper-navigation-size);
  margin-top: calc(-1 * var(--swiper-navigation-size) / 2);
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--swiper-navigation-color, var(--swiper-theme-color));
}
.swiper-button-prev:after,
.swiper-button-next:after {
  font-family: swiper-icons;
  font-size: 1vw !important;
  color: #987ef3;
  width: 4.635vw !important;
  height: 4.635vw !important;
  border-radius: 4.365vw !important;
  border: 1px solid #987ef3;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 7.135vw;
  position: relative;
}
.swiper-button-prev,
.swiper-button-next {
  width: 4.635vw !important;
  height: 32vw;
  align-items: flex-start !important;
}
.swiper-button-prev {
  background-image: url("../assets/fondiu-sl.svg");
  background-size: 2.842vw;
  background-repeat: no-repeat;
  background-position-x: left;
  left: 0;
}
.swiper-button-next {
  background-image: url("../assets/fondiu-sl.svg");
  background-size: 3.542vw;
  background-repeat: no-repeat;
  background-position-x: right;
  right: 0;
}
.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  opacity: 1 !important;
}
.swiper-button-prev.swiper-button-disabled:after,
.swiper-button-next.swiper-button-disabled:after {
  opacity: 0.5 !important;
}

.swiper-slide {
  flex-shrink: 0;
  width: 21.7vw !important;
  height: 100%;
  position: relative;
  transition-property: transform;
}

.contains-blog {
  padding-top: 19.82421875vh;
}

.modalShow {
  display: none;
}

div#diframes {
  width: 57.91666666666667vw;
  height: 36.510416666666664vw;
  margin: auto;
  margin-top: 3.542vw;
}
div#diframess {
  width: 57.91666666666667vw;
  height: 36.510416666666664vw;
  margin: auto;
  margin-top: 3.542vw;
}
.modals {
  position: fixed;
  z-index: 601;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}
.modal-content {
  background-color: #fefefe;
  margin: 7% auto;
  /*padding: 20px;*/
  border: 0.2vw solid #ffffff;
  width: 65.10416666666666vw;
  height: 43.59375vw;
  background-color: rgba(0, 0, 0, 0.4);
}
.MHeader {
  color: #aaa;
  float: right;
  font-size: 1.4583333333333333vw;
  font-weight: bold;
  margin-top: -2.95vw;
  margin-right: -2.84vw;
}

.MHeader:hover,
.MHeader:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.Header {
  display: flex;
  width: 92.5vw;
  height: 10.572916666666666vw;
  padding: 0vw 3.28125vw;
  margin: 0vw;
  justify-content: space-between;
}
.HB1 {
  display: flex;
}
.HB1 img {
  width: 10.260416666666666vw;
  height: auto;
  margin-right: 2.5vw;
  margin-bottom: -6vw;
}
.HB1 p {
  color: #ef67c2;
  font-size: 0.5208333333333333vw;
  align-self: center;
}
.HB2 {
  display: flex;
}
.HB2 a {
  align-self: center;
}
.HB2 p {
  color: #987ef3;
  font-size: 1.1458333333333333vw;
  align-self: center;
}
.HB2 img {
  width: 3.90625vw;
  height: auto;
  margin-left: 2.5vw;
}

.BarMenu {
  width: 95.625vw;
  height: 20.313vw;
  display: flex;
  flex-direction: column;
  margin: auto;
  /*padding-bottom: 9vw;
  border-bottom: 1px solid #FFB6EA; */
}
.BarMenu p {
  color: #ef67c2;
  font-size: 10.417vw;
  margin: 0;
  font-weight: 400;
  font-family: "Gramatika-Medium";
  line-height: 121.3%;
  /*margin-top: -1.7vw;
  margin-left: 5.604166666666666vw;*/
}
.Menu ul {
  display: flex;
  justify-content: space-evenly;
  width: auto;
  margin: 2.865vw auto 0;
  border-top: 1px solid #000000;
  border-bottom: 1px solid #000000;
}
.Menu li {
  font-size: 0.8333333333333334vw;
  /*margin: 0vw 1.5vw;*/
  margin: 0vw;
  list-style: none;
  white-space: nowrap;
  cursor: pointer;
  font-family: "Gramatika-Bold";
  padding: 1.875vw 0 1.771vw;
}

.active_li {
  font-size: 0.8333333333333334vw;

  padding-bottom: 1.5vw;
}

.B1 {
  display: flex;
  flex-flow: wrap;
  margin-top: 2.083333333333333vw;
  margin-left: 1.4vw;
  margin-bottom: 1.4vw;
}
.B1F1 {
  width: 48.180729166666666vw;
  height: 33.75vw;
  margin-right: 0.1vw;
  margin-bottom: 0.1vw;
  /*
  background-position: top;
  background-size: 100%;
  background: round;*/
  background-position: center;
  background-size: 130%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  transition: 0.5s;
}
.B1F1:hover {
  background-size: 150%;
}
.B1F1A {
  width: 32.04895833333333vw;
  height: 18.90625vw;
  transition: 0.5s;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-size: 110%;
  background-position: center;
  background-repeat: no-repeat;
}
.B1F1A:hover {
  background-size: 130%;
}

.B1F1_B1 {
  /*padding-top: 16.4vw;*/
  padding-left: 3.777083333333333vw;
  padding-bottom: 2.344vw;
  background: linear-gradient(0deg, #2a1f46, transparent);
}
.B1F1_B10 {
  /*padding-top: 9.5vw;*/
  padding-left: 2.4302083333333333vw;
  padding-bottom: 2.344vw;
}

.B1F1_B1F1 {
  display: flex;
}
.B1F1_Etiq {
  background-color: #ef67c2;
  color: #ffffff;
  margin-right: 0.8005208333333333vw;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 0.2vw;
  padding-bottom: 0.4vw;
  width: 8vw;
  font-weight: 500;
  font-size: 0.78125vw;
  font-family: "Gramatika-Black";
}
.B1F1_EtiqA {
  padding-top: 0.2vw;
  padding-bottom: 0.4vw;
  width: 8vw;
  background-color: #3702fb;
  color: #ffffff;
  margin-right: 0.8005208333333333vw;
  font-weight: 500;
  font-family: "Gramatika-Black";
}
.B1F1_Date {
  font-size: 0.6770833333333334vw;
  color: #ffffff;
  align-self: center;
  font-family: "IBM Plex Mono";
}
.B1F1_Text {
  width: 31vw;
  font-size: 1.9791666666666665vw;
  color: #ffffff;
  font-weight: 500;
  margin-top: 0.2vw;
  line-height: 2.473958333333333vw;
  font-family: "Gramatika-Bold";
}
.B1F1_TextA {
  width: 25vw;
  font-size: 1.1458333333333333vw;
  font-weight: 500;
  margin-top: 0vw;
  line-height: 1.2640624999999999vw;
  font-family: "Gramatika-Bold";
}

.B2 {
  height: 50.10416666666667vw;
  background-color: #e4e4f9;
}
.B2Img {
  width: 21.302083333333332vw;
  height: 18.59375vw;
  background-repeat: no-repeat;
  /*background: round; */
  transition: 0.5s;
  background-size: 160%;
  background-position: center;
  background-color: #ffb6ea;
}
.B2Img:hover {
  background-size: 180%;
}
.B2F1 {
  width: 17.96875vw;
  text-align: center;
  margin-left: 3.75vw;
  padding-top: 6.041666666666667vw;
  font-weight: 700;
}
.B2F1 p {
  color: #987ef3;
  font-size: 1.5625vw;
  border-bottom: 0.3vw solid #ffb6ea;
  padding-bottom: 1.1187500000000002vw;
  padding-right: 4vw;
  font-family: "Gramatika-Bold";
}
.B2slide {
  display: flex;
  margin-right: 0.52vw;
}

.VueCarousel {
  width: 87vw;
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 2.656vw auto 0;
}

div.swiper-wrapper {
  margin: 0px;
}
.swiper-container {
  padding: 0 3vw;
  margin: 0 2.969vw 0 3.75vw !important;
  /*margin: 0px 7vw!important;
    left: 3.542vw;
    right: 3.542vw;
    position: relative;
    width: 90vw;
    margin: 0;*/
}
.slide {
  /*padding-left: 3.542vw;
        padding-right: 3.542vw;*/
}
.swiper-wrapper {
  display: flex;
  margin: 0 2vw;
  width: fit-content;
}
.wrapaah {
  display: flex;
  margin: 0 2vw;
  width: fit-content;
}

.B2Etiq {
  color: #3702fb;
  font-size: 0.78125vw;
  font-weight: 700;
  margin-top: 2.65625vw;
  font-family: "Gramatika-Black";
}
.B2Text {
  width: 19.1vw;
  height: 5.8vw;
  margin: 0.938vw 0;
  color: black;
  content-visibility: auto;
  font-size: 1.4583333333333333vw;
  line-height: 1.8229166666666667vw;
  font-weight: 500;
  font-family: "Gramatika-Medium";
  transition: 0.5s;
}
.B2Date {
  color: #b2acfc;
  font-size: 0.5208333333333333vw;
  font-weight: 500;
  font-family: "IBM Plex Mono";
}
.B2Post img {
  width: 21.302vw;
}
.swiper-slide {
  width: 21.302vw !important;
  margin-right: 0.365vw !important;
}
.B3F2_B2 img {
  width: 23.021vw;
}
.B3F1 {
  width: 17.96875vw;
  text-align: center;
  margin-left: 3.75vw;
  padding-top: 6.041666666666667vw;
  margin-bottom: 2.969vw;
}
.B3Img {
  width: 30.416666666666664vw;
  height: 30.520833333333336vw;
  background-size: 200%;
  background-repeat: no-repeat;
  background-position: top;
  transition: 0.5s;
}
.B3Img:hover {
  background-size: 220%;
}
.B3Post img {
  width: 30.417vw;
  height: auto;
}
.B3F1 p {
  color: #987ef3;
  font-size: 1.5625vw;
  border-bottom: 0.3vw solid #ffb6ea;
  padding-bottom: 1.1187500000000002vw;
  font-weight: 500;
  padding-right: 1vw;
  font-family: "Gramatika-Bold";
}
.B3B4F2F {
  display: flex;
}
.B3F2 {
  display: flex;
  flex-wrap: wrap;
  width: 63.44791666666667vw;
  margin-left: 7.291666666666667vw;
  /*height: 94vw;
  overflow-y: scroll;*/
}

/** estilos scroll bar */
.B3F2::-webkit-scrollbar {
  -webkit-appearance: none;
}

.B3F2::-webkit-scrollbar:vertical {
  width: 0.5645833333333333vw;
}

.B3F2::-webkit-scrollbar-button:increment,
.B3F2::-webkit-scrollbar-button {
  display: none;
}

.B3F2::-webkit-scrollbar:horizontal {
  height: 0.3645833333333333vw;
}

.B3F2::-webkit-scrollbar-thumb {
  background-color: var(--photography);
  /*border-radius: 1.0416666666666665VW;*/
  border: 0.10416666666666667vw solid var(--color-5);
}

.B3F2::-webkit-scrollbar-track {
  border-radius: 0.3645833333333333vw;
}

.B3F2_B1 {
  margin-bottom: 3.90625vw;
  margin-right: 0.2vw;
}
.B3Info {
  margin-top: 2.083vw;
}
.B3Etiq {
  color: #ef67c2;
  font-size: 0.78125vw;
  font-weight: 700;
  font-family: "Gramatika-Black";
}
.B3Text {
  width: 27.9vw;
  color: black;
  font-size: 1.4583333333333333vw;
  line-height: 1.8229166666666667vw;
  font-weight: 500;
  font-family: "Gramatika-Medium";
  transition: 0.5s;
  margin: 2.448vw 0;
}
.B3Date {
  color: #b2acfc;
  font-size: 0.5208333333333333vw;
  font-weight: 500;
  font-family: "IBM Plex Mono";
}
.B3F2_B2 {
  width: 23.75vw;
  margin-left: 2vw;
}
.B3F2_B2 iframe {
  width: 23.75vw;
  height: 35.208333333333336vw;
  margin-bottom: 2.864583333333333vw;
}

.divPrev3 {
  width: 2.5vw;
  height: 2.5vw;
  display: flex;
  border: 1px solid #ffffff;
  border-radius: 10vw;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1;
  transition: 0.5s;
}
.divNext3 {
  width: 2.5vw;
  height: 2.5vw;
  display: flex;
  border: 1px solid #ffffff;
  border-radius: 10vw;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1;
  transition: 0.5s;
}
.divPrev3:hover,
.divNext3:hover {
  background-color: white;
}
/*.divPrev3 img,.divNext3 img{
  transition: .5s;
}*/
.divPrev3:hover img,
.divNext3:hover img {
  filter: invert(23%) sepia(67%) saturate(4308%) hue-rotate(307deg)
    brightness(88%) contrast(94%);
}
.B4P {
  display: none;
}
.B4_0 {
  width: 100%;
  height: 57.13541666666667vw;
  margin-top: 5vw;
  background-color: #6c00ea;
}
.B4_0F1 {
  display: flex;
  width: 85vw;
  height: 5vw;
  align-items: center;
  margin: auto;
  justify-content: space-between;
  padding-top: 6.927083333333334vw;
}
p.B4_0P1 {
  width: 17.96875vw;
  text-align: center;
  font-size: 1.5625vw;
  font-family: "Gramatika-Bold";
  color: #ffffff;
  border-bottom: 0.3vw solid #ffb6ea;
  padding-bottom: 1.7187500000000002vw;
  margin-left: -3.5vw;
}
.B4_0F0 {
  display: flex;
  height: 3vw;
  align-items: center;
}
.B4_0F0 p {
  font-size: 0.8333333333333334vw;
  font-family: "Gramatika-Black";
  color: #ffffff;
  margin-right: 2vw;
  cursor: pointer;
}
.B4_0img {
  width: 2.5vw;
  height: 2.5vw;
  display: flex;
  border: 1px solid #ffffff;
  border-radius: 10vw;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.B4_0img img {
  width: 0.5vw;
  height: 0.6vw;
}
.B4_0F2 {
  display: flex;
  width: 91vw;
  margin: auto;
  margin-top: 2.604166666666667vw;
}
.B4_0F2B1 {
  width: 28.645833333333332vw;
  height: 37.708333333333336vw;
  margin-right: 0.2vw;
  background-size: 100% 38vw;
  background-position: center;
  transition: 0.5s;
  cursor: pointer;
}
.B4_0F2B1:hover {
  background-size: 110% 43vw;
}
.B4_0F3 {
  display: flex;
  align-items: center;
  /*padding-top: 26.90625vw;*/
  margin-left: 2.275vw;
  margin-bottom: 0.7vw;
}
p.B4_0P2 {
  display: flex;
  width: 8vw;
  height: 1.8229166666666667vw;
  font-size: 0.78125vw;
  line-height: 1.622917vw;
  color: #ffffff;
  font-family: "Gramatika-Black";
  background-color: #3702fb;
  /* align-items: center; */
  justify-content: center;
}
p.B4_0P3 {
  font-size: 0.6770833333333334vw;
  color: #ffffff;
  font-weight: 500;
  font-family: "IBM Plex Mono";
  margin-left: 0.8119791666666666vw;
}
p.B4_0P4 {
  width: 24vw;
  font-size: 1.1458333333333333vw;
  line-height: 1.2640624999999999vw;
  color: #ffffff;
  font-family: "Gramatika-Medium";
  margin-left: 2.275vw;
  margin-bottom: 2vw;
}
.plyButton {
  display: flex;
  width: 4vw;
  height: 4vw;
  align-items: center;
  justify-content: center;
  background-color: hsla(0, 0%, 0%, 0);
  border: 0.2vw solid #ffffff;
  border-radius: 20vw;
  margin: auto;
  margin-top: -22vw;
  margin-left: 13vw;
  position: absolute;
  cursor: pointer;
}
.plyButton img {
  width: 1.3vw;
  height: auto;
}

.B4F1 {
  width: 25.624999999999996vw;
  text-align: center;
  margin-left: 3.75vw;
  padding-top: 6.041666666666667vw;
}
.B4F1 p {
  color: #987ef3;
  font-size: 1.5625vw;
  border-bottom: 0.3vw solid #ffb6ea;
  padding-bottom: 1.1187500000000002vw;
  font-weight: 500;
  font-family: "Gramatika-Bold";
}
.B4F2 {
  display: flex;
  place-content: center;
  margin-top: 7.083vw;
  margin-bottom: 7.291666666666667vw;
}
.B4F2_B1 {
  margin: 0vw 1.5vw;
  text-align-last: center;
  transform: scale(1);
  transition: 0.5s;
}
.B4F2_B1:hover {
  transform: scale(1.1);
}
.B4Image {
  width: 14.322916666666666vw;
  height: 14.322916666666666vw;
  background-repeat: no-repeat;
  background: round;
  background-size: cover;
  border-radius: 30vw;
  margin: auto;
}
.B4Name {
  font-size: 1.25vw;
  font-weight: 500;
  margin-top: 3vw;
  margin-bottom: 0vw;
  font-family: "Gramatika-Medium";
  text-align: center;
}
.B4Puesto {
  font-size: 0.7291666666666666vw;
  font-weight: 400;
  margin: 0.2vw;
  font-family: "IBM Plex Mono";
}
.B4Fund {
  width: 13.9vw;
  margin: 0.2vw auto;
}
::v-deep .B4Fund > p {
  font-size: 0.7291666666666666vw;
  font-weight: 400;
  font-family: "IBM Plex Mono";
  text-align: center;
}
.B1PH {
  display: none;
}

.B5P {
  display: none;
}
.B5 {
  display: flex;
  background-color: #3702fb;
}
.B5B1 {
  margin-left: 10.15625vw;
  align-self: center;
}
p.B5P1 {
  font-size: 2.604166666666667vw;
  color: #b2acfc;
  font-family: "Gramatika-Medium";
}
p.B5P2 {
  width: 21vw;
  font-size: 0.9375vw;
  color: #ffffff;
  line-height: 1.9244791666666667vw;
  font-family: "IBM Plex Mono";
}
img.B5Img1 {
  width: 24.010416666666668vw;
  margin-left: 3.4895833333333335vw;
  margin-right: 5.677083333333333vw;
}
input.sendInp::placeholder {
  font-size: 0.9375vw;
  font-family: "IBM Plex Mono";
  font-weight: 500;
  color: #ffffff;
}
input.sendInp {
  width: 24.739583333333336vw;
  font-size: 0.9375vw;
  font-family: "IBM Plex Mono";
  font-weight: 500;
  color: #ffffff;
  background-color: #3702fb;
  border: 0vw;
  border-bottom: 0.1vw solid #ffffff;
  padding-bottom: 1.0416666666666665vw;
}
form.sendForm {
  display: flex;
  height: 3vw;
  align-items: center;
  margin-top: 13.593749999999998vw;
}
button.sendSubmit {
  display: flex;
  background-color: #ef67c2;
  width: 2.3958333333333335vw;
  height: 2.3958333333333335vw;
  border-radius: 10vw;
  border: 0vw;
  margin-left: -2.8vw;
  margin-bottom: 1.5vw;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

/**** */
.flexinMgz {
  width: auto;
  margin: 4vw 7.6vw;
  display: flex;
}
.S2 {
  margin-left: 7.76vw;
  width: 20.721vw;
}
.S2Visto {
  margin-top: 3.542vw;
}
.S2LMV {
  width: 17.96875vw;
  color: #987ef3;
  font-size: 1.5625vw;
  margin: 3.542vw 0;
  font-weight: 600;
  padding-bottom: 1.25vw;
  border-bottom: 0.3vw solid #ffb6ea;
  font-family: "Gramatika-Bold";
}

.If2 {
  display: none;
}
.S2F2 {
  display: flex;
  margin: 1vw 0vw;
  align-items: center;
}
.S2 iframe {
  margin-left: 0vw;
  margin-bottom: 2.865vw;
}
p.S2Catg {
  color: #ef67c2;
  font-family: "Gramatika-Black";
  margin: 0;
  font-size: 0.781vw;
  line-height: 0.781vw;
}
p.S2Date {
  font-size: 0.5208333333333333vw;
  line-height: 0.5208333333333333vw;
  font-weight: 500;
  /*padding-top: 0.7vw;*/
  margin: 0 0 0 1.5vw;
  font-family: "IBM Plex Mono";
}
p.S2Text {
  width: 20vw;
  color: black;
  font-size: 0.9375vw;
  line-height: 1.171875vw;
  font-weight: 500;
  font-family: "Gramatika-Medium";
  transition: 0.5s;
}
.image-sponsor {
  width: 100%;
}
.Social {
  margin-top: 3vw;
  margin-left: 15.5vw;
}
.Social img {
  margin-right: 1.6666666666666667vw;
}
.SocialI {
  width: 1.0416666666666665vw;
  height: 1.0416666666666665vw;
}
.imgLV {
  width: 20.28125vw;
  height: 8.697916666666666vw;
  margin-top: 2.34375vw;
  overflow: hidden;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position-x: center;
  transition: 0.5s;
}
.imgLV:hover {
  background-size: 110%;
}
.imgLV img {
  width: 23.28125vw;
  height: 8.697916666666666vw;
}

@media (max-width: 768px) {
  .pc {
    display: none;
  }
  .mo {
    display: block;
  }
  .Header {
    display: flex;
    width: 86.5vw;
    height: 28.531400966183575vw;
    padding: 0vw 3.28125vw;
    margin: auto;
    justify-content: space-between;
  }
  .HB1 img {
    width: 42.99516908212561vw;
    height: auto;
    margin-right: 2.5vw;
    margin-bottom: -29vw;
  }
  .HB1 p {
    display: none;
  }
  .HB2 a {
    display: none;
  }
  .HB2 img {
    width: 14.009661835748794vw;
    height: auto;
    margin-left: 2.5vw;
  }

  .BarMenu {
    width: 100%;
    height: 9.420289855072465vw;
    display: flex;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    margin-top: 14vw;
    padding-bottom: 0vw;
  }
  .BarMenu p {
    color: #ef67c2;
    font-size: 9.66183574879227vw;
    margin-top: -16.7vw;
    margin-left: 7.604166666666666vw;
  }
  .Menu {
    overflow: hidden;
    border-top: 1px solid #000000;
    border-bottom: 1px solid #000000;
    overflow-x: auto;
    /*scrollbar-color:transparent;*/
  }
  /* .Menu::-webkit-scrollbar-track {
    background-color: transparent; 
  }*/

  .Menu::-webkit-scrollbar-thumb {
    border: 0.9vw solid white;
    height: 1vw;
  }
  .Menu ul {
    display: flex;
    /*margin-top: 2.5vw;*/
    margin-top: 0;
    /*width: 100vw;*/
    width: fit-content;
    border-top: none;
    border-bottom: none;
    padding-left: 2vw;
    padding-right: 2vw;
    /*margin-left: -65vw;*/
  }
  .Menu li {
    font-size: 3.140096618357488vw;
    margin: 0vw 3.125vw;
    list-style: none;
    cursor: pointer;
    white-space: nowrap;
    border-top: none;
    border-bottom: none;
  }
  .active_li {
    padding-bottom: 2.1vw;
  }

  .B1 {
    display: none;
  }
  .B1PH {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 1.932367149758454vw;
    margin-bottom: 2.898550724637681vw;
  }

  .B1F1A {
    width: 93.96135265700482vw;
    height: 50.96618357487923vw;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .B1F1:hover {
    background-size: 94vw auto;
}
  .B1F1_B1A {
    padding-left: 5.072463768115942vw;
    padding-top: 28.26086956521739vw;
    padding-bottom: 3vw;
    background: linear-gradient(
      0deg,
      rgb(0 0 0 / 75%) 12%,
      rgb(0 0 0 / 0%) 75%
    );
  }
  .B1F1_EtiqA {
    padding-top: 0.725vw;
    padding-bottom: 0.725vw;
    width: 15vw;
    background-color: #ef67c2;
    color: #ffffff;
    margin-right: 2.1739130434782608vw;
    font-size: 1.4492753623188406vw;
    line-height: 1.4492753623188406vw;
    margin-bottom: 0;
    font-weight: 500;
    height: 3.140vw;
  }
  .B1F1_Date {
    font-size: 1.4492753623188406vw;
    color: #ffffff;
    align-self: center;
    margin-top: 0;
    font-weight: 500;
  }
  .B1F1_TextA {
    width: 69vw;
    font-size: 3.140096618357488vw;
    line-height: 3.7053140096618358vw;
    font-weight: 500;
    color: #ffffff;
  }

  .B1F1B {
    width: 46.85vw;
    height: 48.06763285024155vw;
    margin-right: 0.2vw;
    margin-bottom: 0.2vw;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .B1F1_B1B {
    padding-left: 5.072463768115942vw;
    padding-top: 27.053140096618357vw;
    padding-bottom: 3vw;
    background: linear-gradient(
      0deg,
      rgb(0 0 0 / 75%) 12%,
      rgb(0 0 0 / 0%) 75%
    );
  }
  .B1F1_EtiqB {
    padding-top: 0.725vw;
    padding-bottom: 0.725vw;
    width: 15vw;
    background-color: #3702fb;
    color: #ffffff;
    margin-right: 2.1739130434782608vw;
    font-size: 1.4492753623188406vw;
    line-height: 1.4492753623188406vw;
    margin-bottom: 0;
    font-weight: 500;
    height: 3.140vw;
  }
  .B1F1_TextB {
    width: 37vw;
    font-size: 3.140096618357488vw;
    line-height: 3.7053140096618358vw;
    font-weight: 500;
    color: #ffffff;
    margin-top: 0vw;
    font-family: "Gramatika-Bold";
  }

  .B1F1C {
    width: 93.96135265700482vw;
    height: 30.434782608695656vw;
    margin-bottom: 0.2vw;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 94vw auto;
    background-position-y: top;
  }
  .B1F1_B1C {
    padding-left: 5.072463768115942vw;
    padding-top: 0.285024154589372vw;
    padding-bottom: 3vw;
    background: linear-gradient(
      0deg,
      rgb(0 0 0 / 75%) 12%,
      rgb(0 0 0 / 0%) 75%
    );
  }
  .B1F1_B1F1 {
    display: flex;
    margin-bottom: 3.3816425120772946vw;
    /*eight: 4.8vw;*/
}
  .B1F1_EtiqC {
    padding-top: 0.725vw;
    padding-bottom: 0.725vw;
    width: 15vw;
    font-weight: 500;
    background-color: #851bff;
    color: #ffffff;
    margin-right: 2.1739130434782608vw;
    font-size: 1.4492753623188406vw;
    line-height: 1.4492753623188406vw;
    height: 3.14vw;
    margin-bottom: 0;/**/
    display: flex;
    align-items: center;
    justify-content: center;
}
  .B1F1_TextC {
    width: 75vw;
    font-size: 3.140096618357488vw;
    line-height: 3.7053140096618358vw;
    font-weight: 500;
    color: #ffffff;
    margin-top: 0vw;
    font-family: "Gramatika-Bold";
  }

  .B2 {
   /* height: 107.72946859903381vw;*/
    height: 93.729469vw;
    background-color: #e4e4f9;
    overflow: hidden;
  }
  .B2F1 {
    width: 54.347826086956516vw;
    text-align: center;
    margin-left: 3.75vw;
    padding-top: 6.041666666666667vw;
  }
  .B2F1 p {
    color: #987ef3;
    font-size: 4.3478260869565215vw;
    border-bottom: 0.3vw solid #ffb6ea;
    padding-bottom: 2.1187500000000002vw;
    font-weight: 500;
    text-align: left;
    padding-left: 5vw;
    font-family: "Gramatika-Bold";
  }
  .PButton {
    width: 1.4492753623188406vw;
    margin-top: 3.7vw;
  }
  .NButton {
    width: 1.4492753623188406vw;
    margin-top: 3.7vw;
  }

  .B2Img {
    width: 39.130434782608695vw;
    height: 43.47826086956522vw;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
  }
  .B2Etiq {
    font-size: 1.932367149758454vw;
    font-weight: 500;
    color: #ef67c2;
  }
  .B2Text {
    width: 36.9vw;
    height: 12vw;
    font-size: 3.140096618357488vw;
    line-height: 3.92512077294686vw;
    font-weight: 500;
    overflow: hidden;
  }
  .B2Date {
    font-size: 1.932367149758454vw;
    font-weight: 500;
  }
  .B2Info {
    height: auto;
}
  .slideA {
    display: none;
  }
  .slideB {
    display: block !important;
    height: 75vw!important;
  }
  p.notfound{
    font-size: 3.875vw;
    padding-bottom: 5vw;
  }
  .header-c-magazine p{
    font-size: 6.125vw;
  }
  .B3F1 {
    width: 54.347826086956516vw;
    text-align: center;
    margin-left: 3.75vw;
    padding-top: 6.041666666666667vw;
  }
  .B3F1 p {
    color: #987ef3;
    font-size: 4.3478260869565215vw;
    border-bottom: 0.3vw solid #ffb6ea;
    padding-bottom: 2.1187500000000002vw;
    font-weight: 500;
    text-align: left;
    padding-left: 5vw;
  }
  .B3F2 {
    display: block;
    flex-wrap: wrap;
    width: 87.44791666666667vw;
    margin-left: 7.291666666666667vw;
  }
  .B3F2_B1 {
    margin-bottom: 2vw;
    padding-bottom: 2vw;
    margin-right: 0.2vw;
    border-bottom: 0.1vw solid #e4e4f9;
  }
  .B3Post {
    display: flex;
  }
  .B3Img {
    width: 39.85507246376812vw;
    height: 27.053140096618357vw;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .B3Img:hover {
    background-size: cover;
}
  .B3Info {
    margin-left: 7.246376811594203vw;
  }
  .B3Etiq {
    font-size: 1.932367149758454vw;
    font-weight: 500;
  }
  .B3Text {
    width: 36vw;
    font-size: 3.140096618357488vw;
    line-height: 3.92512077294686vw;
  }
  .B3Date {
    font-size: 1.932367149758454vw;
    font-weight: 500;
  }
  .B3F2_B2 {
    display: none;
  }
.B4P_Video{

}
  .B4P {
    display: block;
    background-color: #6c00ea;
    width: 100%;
    height: 127.29468599033818vw;
    margin-top: 23.42995169082126vw;
  }
  .B4P_F1 {
    display: flex;
    width: 81vw;
    align-items: center;
    margin: auto;
    justify-content: space-between;
    padding-top: 5.628019323671497vw;
    margin-bottom: 5.628019323671497vw;
  }
  p.B4P_P1 {
    width: 44.101449vw;
    font-size: 4.3478260869565215vw;
    color: #987ef3;
    font-family: "Gramatika-Bold";
    border-bottom: 0.3vw solid #ffb6ea;
    padding-bottom: 3.864734299516908vw;
    padding-left: 3vw;
  }
  .B4P_Img1 {
    display: flex;
    width: 9.66183574879227vw;
    height: 9.66183574879227vw;
    border: 0.3vw solid #ffffff;
    border-radius: 20vw;
    align-items: center;
    justify-content: center;
  }
  .B4P_Img1 img {
    width: 2.4154589371980677vw;
    height: 2.4154589371980677vw;
  }
  .B4P_imgP {
    display: flex;
    width: 15.942028985507244vw;
    height: 15.942028985507244vw;
    border: 1.1vw solid #ffffff;
    border-radius: 15vw;
    align-items: center;
    justify-content: center;
  }
  .B4P_imgP img {
    width: 6.176328502415459vw;
    height: 6.929951690821257vw;
  }
  .B4_0 {
    display: none;
  }
  .B4_0F2B1 {
    width: 70.53140096618358vw;
    height: 88.88888888888889vw;
    /*margin-right: 0vw;*/
    margin: auto;
    background: round;
  }
  .B4_0F2B1:hover {
    background-size: 100%;
}
  p.B4_0P2 {
    width: 13.526570048309178vw;
    height: 2.657004830917874vw;
    font-size: 1.4492753623188406vw;
  }
  .B4P_mid {
    height: 53vw;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

  .B4_0F3 {
    padding-top: 13.90625vw;
    margin-left: 7.487922705314009vw;
  }
  p.B4_0P3 {
    font-size: 1.4492753623188406vw;
    margin-left: 2.07487922705314vw;
  }
  p.B4_0P4 {
    width: 54vw;
    font-size: 3.140096618357488vw;
    line-height: 3.7053140096618358vw;
    margin-left: 0vw;
    text-align: left;
  }

  .B4F1 {
    width: 60.14492753623188vw;
    text-align: center;
    margin-left: 3.75vw;
    padding-top: 6.041666666666667vw;
  }
  .B4F1 p {
    color: #987ef3;
    font-size: 4.3478260869565215vw;
    border-bottom: 0.3vw solid #ffb6ea;
    padding-bottom: 2.1187500000000002vw;
    font-weight: 500;
  }
  .B4F2 {
    display: none;
  }

  .B4B2 {
    display: block;
    text-align: center;
    padding-top: 21.73913043478261vw;
  }
  .B4B2 img {
    width: 87.92270531400966vw;
    height: auto;
    margin-top: 8.695652173913043vw;
    margin-bottom: 42.99516908212561vw;
  }

  .B4F2_B1 img {
    width: 28.26086956521739vw;
    height: auto;
  }
  .B4Image {
    width: 28.26086956521739vw;
    height: 28.26086956521739vw;
    margin: auto;
  }
  .B4F2_B1:hover {
    transform: scale(1);
}
  .B4Name {
    font-size: 3.6231884057971016vw;
    font-weight: 500;
  }
  .B4Puesto {
    font-size: 2.4154589371980677vw;
    font-weight: 500;
    text-align: center;
  }
  .B4Fund {
    width: 28vw;
  }
::v-deep .B4Fund > p {
    font-size: 2.4154589371980677vw;
    font-weight: 500;
  text-align: center;
}

  .modal-content {
    margin-top: 45vw;
    width: 74.104167vw;
    height: 150.59375vw;
  }
  .B4P .modal-content {
    margin-top: 45vw;
    width: 86.104167vw;
    height: 62VW;
  }
  .B4P div#diframess {
    width: 76vw;
    height: 58.510417vw;
    margin: auto;
    margin-top: 3.5vw;
  }
   .B4P .MContent iframe {
    width: 75.916667VW;
    height: 53VW;
  }
  .MHeader {
    margin-top:-8.95vw;
    margin-right: -5.84vw;
  }
  div#diframess {
    width: 71vw;
    height: 36.510416666666664vw;
    margin: auto;
    margin-top: 3.5vw;
  }

  .CustomDiv {
    display: block;
    height: 47vw;
  }
  .divcol {
    width: 28.26086956521739vw;
    height: 28.26086956521739vw;
    background: round;
    margin: auto;
  }
  .customSlide {
    display: flex;
    place-content: center;
    width: 71vw;
    content-visibility: auto;
    margin: auto;
  }
  .nuevoSlide {
    display: flex;
    width: 31vw;
    height: 48vw;
    margin-right: 4.3478260869565215vw;
  }

  .btnP {
    border: 1px solid black;
    background-color: rgba(255, 255, 255, 0);
    border-color: #987ef3;
    border-radius: 30vw;
    width: 9.66183574879227vw;
    height: 9.66183574879227vw;
    margin-top: -44vw;
    margin-right: 4vw;
    z-index: 1;
  }
  .btnN {
    border: 1px solid black;
    background-color: rgba(255, 255, 255, 0);
    border-color: #987ef3;
    border-radius: 30vw;
    width: 9.66183574879227vw;
    height: 9.66183574879227vw;
    margin-top: -44vw;
    margin-right: 4vw;
    z-index: 1;
    margin-left: 66vw;
  }
  img.PButton2 {
    width: 1.4492753623188406vw;
    margin-top: 0.7vw;
  }
  .nuevoButton {
    margin-top: -37vw;
    margin-left: 3vw;
  }

  .B5 {
    display: none;
  }
  .B5P {
    display: block;
    background-color: #3702fb;
  }
  form.sendForm {
    height: 9vw;
    margin-top: 13.593749999999998vw;
    margin-left: 15vw;
  }
  .B5B1 {
    margin-left: 14vw;
    align-self: center;
    padding-top: 12.318840579710146vw;
  }
  p.B5P1 {
    font-size: 8.454106280193237vw;
  }
  p.B5P2 {
    width: 70vw;
    font-size: 2.898550724637681vw;
    line-height: 5.951690821256038vw;
  }
  input.sendInp::placeholder {
    font-size: 2.898550724637681vw;
  }
  input.sendInp {
    width: 66.90821256038647vw;
    font-size: 2.898550724637681vw;
    border-bottom: 0.1vw solid #ffffff;
    padding-bottom: 4.830917874396135vw;
  }
  button.sendSubmit {
    display: flex;
    background-color: #ef67c2;
    width: 9.178743961352657vw;
    height: 9.178743961352657vw;
    border-radius: 20vw;
    border: 0vw;
    margin-left: -9.8vw;
    margin-bottom: 5.5vw;
  }
  .plyButton {
    display: flex;
    width: 4vw;
    height: 4vw;
    align-items: center;
    justify-content: center;
    background-color: hsla(0, 0%, 0%, 0);
    border: 0.2vw solid #ffffff;
    border-radius: 20vw;
    margin: auto;
    margin-top: 0vw;
    margin-left: 0vw;
    position: absolute;
    cursor: pointer;
  }
  .B5Img1 {
    display: none;
  }
  img.B5Img2 {
    width: 96.1352657004831vw;
    margin-bottom: -2vw;
  }.flexinMgz[data-v-49500f8e] {
    width: auto;
    margin: 4vw 7.6vw;
    display: flex;
    flex-direction: column;
}
.S2  {
    margin-left: 0vw;
    width: 100%;
}
.S1.mgz {
    width: 56.208vw;
    /* margin: auto; */
    padding: 2vw 0;
}
.woml{
margin-left: 0;
}
  .S2Visto {
    display: none;
  }
}
</style>
